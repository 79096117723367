import { createBox } from "@dessert-box/react";

import { getSprinkles } from "~styles/getSprinkles.css";

import type { ComponentProps } from "react";

/**
 * Polymorphic Box component from `@dessert-box/react`. Works with
 * Vanilla Extract Sprinkles API to allow quick styling of a broad range
 * of HTML elements. Is a foundational component of many other components, and
 * also useful for rapid prototyping.
 */
export const Box = createBox({
  atoms: getSprinkles,
});

export type BoxProps = ComponentProps<typeof Box>;
