import React, { forwardRef, useMemo } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { getButtonStyles } from "../Button/styles.css";
import { IconFontAwesome } from "../IconFontAwesome";
import { Link } from "../Link";
import { computeButtonLinkIconLeft } from "./_computeButtonLinkIconLeft";
import { computeButtonLinkIconRight } from "./_computeButtonLinkIconRight";

import type { VariantButtonAppearanceEnum } from "../Button/styles.css";
import type { IconFontAwesomeProps } from "../IconFontAwesome";
import type { LinkProps } from "../Link";
import type { ReactNode, Ref } from "react";
import type { VariantUiSizeEnum } from "~styles/common/variantUiScale.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface ButtonLinkProps
  extends LinkProps,
    Omit<GetSprinklesArgs, "color"> {
  appearance?: VariantButtonAppearanceEnum;
  children?: ReactNode;
  className?: string;
  iconLeft?: IconFontAwesomeProps["icon"];
  iconRight?: IconFontAwesomeProps["icon"];
  id?: string;
  isCentered?: boolean;
  name?: string;
  shouldHaveArrowIcon?: boolean;
  size?: VariantUiSizeEnum;
  type?: "button" | "submit" | "reset";
  isCurrentPage?: boolean;
}

/**
 * Renders a link as a button component. Incorporates website-specific Gatsby link logic
 * @deprecated `ButtonLink` should be factored out in favor of passing `Link` to polymorphic button component
 */
export const ButtonLink = forwardRef(
  (
    {
      appearance = "primary",
      children,
      className: userClassName,
      iconLeft,
      iconRight,
      shouldHaveArrowIcon = true,
      marginX,
      isCentered,
      id,
      size = "md",
      isCurrentPage,
      ...rest
    }: ButtonLinkProps,
    ref: Ref<HTMLAnchorElement> | undefined
  ) => {
    const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

    /**
     * If `iconRight` is not provided, compute the icon based on the `appearance`
     * prop. We should be using an arrow unless otherwise specified (but only if
     * `appearance` is not `primary | secondary`)
     */
    const computedIconRight = useMemo(() => {
      return computeButtonLinkIconRight({
        appearance,
        iconRight,
        shouldHaveArrowIcon,
      });
    }, [appearance, iconRight, shouldHaveArrowIcon]);

    /**
     * If `iconLeft` is not provided, compute the icon based on the `appearance`
     * prop. We should be using an arrow unless otherwise specified (but only if
     * `appearance` is not `tertiary`)
     */
    const computedIconLeft = useMemo(() => {
      return computeButtonLinkIconLeft({
        appearance,
        iconLeft,
        shouldHaveArrowIcon,
      });
    }, [appearance, iconLeft, shouldHaveArrowIcon]);

    const buttonClassNames = clsx(
      getButtonStyles({ appearance, size, isCurrentPage }),
      getSprinkles({
        ...atomProps,
        marginX: isCentered ? "auto" : marginX,
      }),
      userClassName
    );

    return (
      <Link className={buttonClassNames} id={id} ref={ref} {...otherProps}>
        {computedIconLeft && (
          <IconFontAwesome
            color={appearance === "tertiary" ? "ui_primary_base" : undefined}
            icon={computedIconLeft}
          />
        )}
        {children}
        {computedIconRight && <IconFontAwesome icon={computedIconRight} />}
      </Link>
    );
  }
);
