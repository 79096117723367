import React, { forwardRef } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { IconFontAwesome } from "../IconFontAwesome";
import { getButtonStyles } from "./styles.css";

import type { IconFontAwesomeProps } from "../IconFontAwesome";
import type { VariantButtonAppearanceEnum } from "./styles.css";
import type { ButtonHTMLAttributes, LegacyRef } from "react";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import type { VariantUiSizeEnum } from "~styles/common/variantUiScale.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Omit<GetSprinklesArgs, "color"> {
  appearance?: VariantButtonAppearanceEnum;

  iconLeft?: IconFontAwesomeProps["icon"];
  iconRight?: IconFontAwesomeProps["icon"];
  ctaArrow?: boolean;
  background?: VariantBackgroundColorEnum;
  isCentered?: boolean;

  name?: string;
  size?: VariantUiSizeEnum;
  type?: "button" | "submit" | "reset";

  isCurrentPage?: boolean;

  /** @deprecated */
  color?: string | undefined;
}

/** Renders a button component. incorporates website-specific gatsby link logic */
export const Button = forwardRef(
  (
    {
      appearance = "primary",
      children,
      className: userClassName,
      disabled,
      iconLeft,
      iconRight,
      ctaArrow,
      background,
      isCentered,
      marginX,
      id,
      name,
      onClick,
      size = "md",
      type = "button",
      isCurrentPage,
      // eslint-disable-next-line unused-imports/no-unused-vars
      color, // <-- this is deprecated — can be removed once the CMS json has been cleaned
      ...rest
    }: ButtonProps,
    ref
  ) => {
    const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

    const dynamicIconRight = ctaArrow ? faArrowRightLong : iconRight;

    const buttonClassNames = clsx(
      getButtonStyles({ appearance, size, background, isCurrentPage }),
      getSprinkles({
        ...atomProps,
        marginX: isCentered ? "auto" : marginX,
      }),
      atomProps,
      userClassName
    );

    let iconColor: IconFontAwesomeProps["color"];
    if (appearance === "tertiary") {
      iconColor = "ui_primary_base";
    }
    if (background === "background_lavender") {
      iconColor = "text_highContrast";
    }

    return (
      <button
        className={buttonClassNames}
        aria-disabled={disabled}
        disabled={disabled}
        id={id}
        data-testid={id}
        name={name}
        onClick={onClick}
        ref={ref as LegacyRef<HTMLButtonElement>}
        // eslint-disable-next-line react/button-has-type
        type={type}
        {...otherProps}
      >
        {(iconLeft || appearance === "tertiary") && (
          <IconFontAwesome
            color={iconColor}
            icon={iconLeft || faArrowRightLong}
          />
        )}
        {children}
        {dynamicIconRight && (
          <IconFontAwesome color={iconColor} icon={dynamicIconRight} />
        )}
      </button>
    );
  }
);
